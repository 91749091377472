import React from 'react';
import Helmet from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/Banner';
import Gallery from '../components/Gallery';
import poster from '../assets/images/separateImages/reklamos derinimas.jpg';
import SinglePicture from '../components/SinglePicture';

const SignBoards = () => (
  <Layout>
    <Helmet
      title="Velgama - Iškabos"
      meta={[
        { name: 'description', content: 'Reklaminės iškabos' },
      ]}
    />
    <div id="main">
      <Banner h1="Iškabos" paragraph="Šviečiančios, tūrinės... ir kitos pagal Jūsų pageidavimus" bannerType="signboards-banner" />
      <section id="one" className="spotlights">
        <StaticQuery
          query={galleryQuery}
          render={(data) => <Gallery data={data} />}
        />

      </section>
      <SinglePicture alt="Plakatas apie reklamines iškabas" src={poster} />
    </div>
  </Layout>
);

const galleryQuery = graphql`
query signboardsImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images-signboards" } },
      sort: {order: ASC, fields: name}
      ) {
      edges {
        node {
          dir
          name
          childImageSharp{
            fluid (maxWidth: 1200, maxHeight: 1000){
                ...GatsbyImageSharpFluid
              }
          }
        }
      }
    }
  }
`;

export default SignBoards;
